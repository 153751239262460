import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChannelComponent } from './components/channel/channel.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { BillingComponent } from './components/dashboard/billing/billing.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { ProfileComponent } from './components/dashboard/profile/profile.component';
import { SecurityComponent } from './components/dashboard/security/security.component';
import { EbookDetailComponent } from './components/ebook-detail/ebook-detail.component';
import { EbookDetailResolver } from './components/ebook-detail/ebook-detail.resolver';
import { EbookComponent } from './components/ebook/ebook.component';
import { GivingComponent } from './components/giving/giving.component';
import { HomeComponent } from './components/home/home.component';
import { JoinComponent } from './components/join/join.component';
import { MobileAppComponent } from './components/mobile-app/mobile-app.component';
import { NetworkComponent } from './components/network/network.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramsResolver } from './components/programs/programs.resolver';
import { SigninPageComponent } from './components/signin-page/signin-page.component';
import { SignupPageComponent } from './components/signup-page/signup-page.component';
import { WatchdailyComponent } from './components/watchdaily/watchdaily.component';
import { WatchDailyResolver } from './components/watchdaily/watchdaily.resolver';
import { AuthGuard } from './guards/auth.guard';
import { EbookGuard } from './guards/ebook.guard';
import { GivingGuard } from './guards/giving.guard';
import { HomeGuard } from './guards/home.guard';
import { JoinGuard } from './guards/join.guard';
import { MobileAppGuard } from './guards/mobile-app.guard';
import { VodGuard } from './guards/vod.guard';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SitecantreachComponent } from './sitecantreach/sitecantreach.component';
import { IframeLiveComponent } from './components/iframe-live/iframe-live.component';
import { LiveGuard } from './guards/live.guard';
import { IframePlayerComponent } from './components/iframe-player/iframe-player.component';
import { IframePlayerResolver } from './components/iframe-player/iframe-player.resolver';
import { ProgramsM3uComponent } from './components/programs-m3u/programs-m3u.component';
import { M3uDetailPageComponent } from './components/m3u-detail-page/m3u-detail-page.component';
import { M3uDetailPageResolver } from './components/m3u-detail-page/m3u-detail-page.resolver';
import { ProgramsM3uResolver } from './components/programs-m3u/programs-m3u.resolver';
import { ChannelGuard } from './guards/channel.guard';
import { NetworkGuard } from './guards/network.guard';
import { EbookResolver } from './components/ebook/ebook.resolver';
import { HomeResolver } from './components/home/home.resolver';
import { PaymentnotdonepageComponent } from './paymentnotdonepage/paymentnotdonepage.component';

export const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  {
    path: 'play',
    loadComponent: () => import('./components/programs-m3u/programs-m3u.component').then(m => m.ProgramsM3uComponent),
    resolve: { data: ProgramsM3uResolver }
  },
  {
    path: 'main',
    loadComponent: () => import('./components/home/home.component').then(m => m.HomeComponent),
    canActivate: [HomeGuard], 
    resolve: { data: HomeResolver }
  },
  {
    path: 'channel',
    loadComponent: () => import('./components/channel/channel.component').then(m => m.ChannelComponent),
    canActivate: [ChannelGuard]
  },
  {
    path: 'network',
    loadComponent: () => import('./components/network/network.component').then(m => m.NetworkComponent),
    canActivate: [NetworkGuard]
  },
  {
    path: 'm3u-detail',
    loadComponent: () => import('./components/m3u-detail-page/m3u-detail-page.component').then(m => m.M3uDetailPageComponent),
    resolve: { data: M3uDetailPageResolver }
  },
  {
    path: 'giving',
    loadComponent: () => import('./components/giving/giving.component').then(m => m.GivingComponent),
    canActivate: [GivingGuard]
  },
  {
    path: 'mobileApp',
    loadComponent: () => import('./components/mobile-app/mobile-app.component').then(m => m.MobileAppComponent),
    canActivate: [MobileAppGuard],
  },
  {
    path: 'vod',
    loadComponent: () => import('./components/watchdaily/watchdaily.component').then(m => m.WatchdailyComponent),
    resolve: { data: WatchDailyResolver },
    canActivate: [VodGuard],
  },
  {
    path: 'signin',
    loadComponent: () => import('./components/signin-page/signin-page.component').then(m => m.SigninPageComponent)
  },
  {
    path: 'signup',
    loadComponent: () => import('./components/signup-page/signup-page.component').then(m => m.SignupPageComponent)
  },
  {
    path: 'site-error',
    loadComponent: () => import('./sitecantreach/sitecantreach.component').then(m => m.SitecantreachComponent)
  },
  {
    path: 'join',
    loadComponent: () => import('./components/join/join.component').then(m => m.JoinComponent),
    canActivate: [JoinGuard]
  },
  // { path: 'program', component: ProgramsComponent},
  {
    path: 'video/:id',
    loadComponent: () => import('./components/programs/programs.component').then(m => m.ProgramsComponent),
    resolve: { data: ProgramsResolver },
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./components/contact-us/contact-us.component').then(m => m.ContactUsComponent),
  },
  {
    path: 'Dashboard',
    loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent),
      },
      {
        path: 'menu',
        loadComponent: () => import('./components/dashboard/menu/menu.component').then(m => m.MenuComponent),
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          {
            path: 'profile',
            loadComponent: () => import('./components/dashboard/profile/profile.component').then(m => m.ProfileComponent)
          },
          {
            path: 'security',
            loadComponent: () => import('./components/dashboard/security/security.component').then(m => m.SecurityComponent)
          },
          { path: 'billing', component: BillingComponent },
        ],
      },
      //   {
      //     path: 'security',
      //     component: SecurityComponent,
      // },+
    ],
  },
  {
    path: 'ebook-list',
    loadComponent: () => import('./components/ebook/ebook.component').then(m => m.EbookComponent),
    canActivate: [EbookGuard],
    resolve: { data: EbookResolver },
  },
  {
    path: 'ebook/:id',
    loadComponent: () => import('./components/ebook-detail/ebook-detail.component').then(m => m.EbookDetailComponent),
    canActivate: [EbookGuard],
    resolve: { data: EbookDetailResolver },
  },
  {
    path: 'frame',
    loadComponent: () => import('./components/iframe-live/iframe-live.component').then(m => m.IframeLiveComponent),
    canActivate: [LiveGuard],
  },
  {
    path: 'liveVideo/:id',
    loadComponent: () => import('./components/iframe-player/iframe-player.component').then(m => m.IframePlayerComponent),
    resolve: { data: IframePlayerResolver },
  },
  {
    path: 'payment-not-done',
    component: PaymentnotdonepageComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./pagenotfound/pagenotfound.component').then(m => m.PagenotfoundComponent),
  },
];
