import { Inject, Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { LoginModel } from 'src/app/Models/login.model';
import { ResourceService } from '../resource/resource.service';
import { ssrWindow } from 'ssr-window';
import { ConfigService } from '../config.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  sendURL: any;
  constructor(
    public resourceService: ResourceService,
    private _ConfigService: ConfigService,
    @Inject(DOCUMENT) private _Document: Document,
  ) {
    // this.sendURL = `https://testdev2.nowcast.cc/`;
    // this.sendURL = 'https://testdev1.nowcast.cc/';
    // this.sendURL = "https://thefranchisetv.com/"
    // this.sendURL = `${window.location.origin}/`;
    this.sendURL = `${this._Document.location.origin}/`;
  }

  loginUser(formData: LoginModel) {
    const loginUrl = this.sendURL + appConfig.logIn;
    return this.resourceService.postData(loginUrl, formData);
  }

  logOutUser() {
    const logOutUrl = this.sendURL + appConfig.logOut;
    return this.resourceService.getData(logOutUrl);
  }

  forgotPassword(data: any) {
    const loginUrl = this.sendURL + `${appConfig.user}/forgetPasswordLink`;
    return this.resourceService.postData(loginUrl, data);
  }
}
