import { Inject, Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../resource/resource.service';
import { ssrWindow } from 'ssr-window';
import { ConfigService } from '../config.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  mediaRoot: string;
  sendURL: any;
  // customisePageData = new Subject();
  // customisePageData$ = this.customisePageData.asObservable();
  constructor(
    public resourceService: ResourceService,
    private _ConfigService: ConfigService,
    @Inject(DOCUMENT) private _Document: Document,
  ) {
    this.sendURL = `${this._Document.location.origin}/`;
    // this.sendURL = 'https://testdev1.nowcast.cc/';
    // this.sendURL = "https://thefranchisetv.com/"
    this.mediaRoot = this._ConfigService.api;
  }

  ValidateEmail(inputText: any) {
    // let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    // return regex.test(inputText)
    return String(inputText)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  sendPageData = new BehaviorSubject([]);
  sendPageData$ = this.sendPageData.asObservable();

  skipState = new BehaviorSubject(false);
  skipState$ = this.skipState.asObservable();

  updateSkipState(data: any) {
    this.skipState.next(data);
  }

  sendData(data: any) {
    this.sendPageData.next(data);
  }
  getData() {
    return this.sendPageData;
  }
  // updateData(data: any){
  //     this.customisePageData.next(data)
  //   }

  //   loginUser(formData: LoginModel) {
  //     const loginUrl = appConfig.logIn;
  //     return this.resourceService.postData(loginUrl, formData);
  //   }

  getMediaItemWithout(page: any, size: any, type: any) {
    const getMediaItemWithoutLoginUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/displayMediaItemInfoWithoutLogin?page=${page}&size=${size}&type=${type}`;
    return this.resourceService.getData(getMediaItemWithoutLoginUrl);
  }


  getDeviceNetworkAddress() {
    const prepareUrl = `${environment.dashboard}admin-service/api/v1/viewerAnalytics/getUserIpFromRequest`;
    return this.resourceService.getData(prepareUrl);
  }

  getMediaDataWithout(searchkey: string) {
    const getMediaDataWithoutLoginUrl =
      this.sendURL + `${appConfig.displayMediaItemInfo}/mediaItemsAndSeries?searchkey=${searchkey}`;
    return this.resourceService.getData(getMediaDataWithoutLoginUrl);
  }

  getMediaDataWith(searchkey: string) {
    const getMediaDataWithLoginUrl =
      this.sendURL + `${appConfig.displayMediaItemInfo}/mediaItemAndSeriesList?searchkey=${searchkey}`;
    return this.resourceService.getData(getMediaDataWithLoginUrl);
  }

  getMediaItemWith(page: any, size: any, type: any) {
    if (type) {
      var getMediaItemWithLoginUrl =
        this.sendURL +
        `${appConfig.displayMediaItemInfo}/displayMediaItemInfoWithLogin?page=${page}&size=${size}&type=${type}`;
    } else {
      var getMediaItemWithLoginUrl =
        this.sendURL + `${appConfig.displayMediaItemInfo}/displayMediaItemInfoWithLogin?page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getMediaItemWithLoginUrl);
  }

  getRelatedMediaItemWithout(page: any, size: any, id: any) {
    const getRelatedMediaItemWithoutLoginUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/relatedMediaItem?currentPlayingMediaItemId=${id}&page=${page}&size=${size}`;
    return this.resourceService.getData(getRelatedMediaItemWithoutLoginUrl);
  }

  getRelatedMediaItemWith(page: any, size: any, id: any) {
    const getRelatedMediaItemWithoutUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/relatedMediaItemWithLogin?currentPlayingMediaItemId=${id}&page=${page}&size=${size}`;
    return this.resourceService.getData(getRelatedMediaItemWithoutUrl);
  }

  getMediaDataByIdWith(id: any) {
    const getMediaDataByIdUrl =
      this.sendURL + `${appConfig.displayMediaItemInfo}/nextVideoFromList?currentPlayingMediaItemId=${id}`;
    return this.resourceService.getData(getMediaDataByIdUrl);
  }

  getvedioPlayDuration(id: any) {
    const getvedioPlayDurationIdUrl = this.sendURL + `${appConfig.displayMediaItemInfo}/${id}/videoPlayDetails`;
    return this.resourceService.getData(getvedioPlayDurationIdUrl);
  }

  deletevedioPlayDuration(id: any) {
    const deletevedioPlayDurationIdUrl = this.sendURL + `${appConfig.displayMediaItemInfo}/${id}/videoPlayDetails`;
    return this.resourceService.getData(deletevedioPlayDurationIdUrl);
  }

  getMediaDataByIdWithout(id: any) {
    const getMediaDataByIdUrl =
      this.sendURL + `${appConfig.displayMediaItemInfo}/nextVideoFromListWithoutLogin?currentPlayingMediaItemId=${id}`;
    return this.resourceService.getData(getMediaDataByIdUrl);
  }

  pageCustomizationData() {
    const pageCustomizationUrl = this.sendURL + `${appConfig.pageCustomization}/commonPageCustomization`;
    return this.resourceService.getData(pageCustomizationUrl);
  }

  updateVideoPause(data: any) {
    const videoPlayUrl = this.sendURL + `${appConfig.displayMediaItemInfo}/saveVideoPlayDetails`;
    return this.resourceService.postData(videoPlayUrl, data);
  }

  addToFavourites(mediaItemId: any) {
    const addToFavouritesUrl = this.sendURL + `${appConfig.favourites}/${mediaItemId}`;
    return this.resourceService.postData(addToFavouritesUrl);
  }

  removeFavourites(ids: any) {
    const removeFavouritesUrl = this.sendURL + `${appConfig.favourites}?ids=${ids}`;
    return this.resourceService.deleteData(removeFavouritesUrl);
  }

  getListOfFavourites() {
    const favouritesListUrl = this.sendURL + `${appConfig.favourites}/getFavouritesListWithLogin`;
    return this.resourceService.getData(favouritesListUrl);
  }

  getContinuePlayList() {
    const ContinuePlayListUrl = this.sendURL + `${appConfig.displayMediaItemInfo}/videoPlayDetails`;
    return this.resourceService.getData(ContinuePlayListUrl);
  }

  subscriptionPlanList() {
    const subscriptionPlanListUrl = this.sendURL + `${appConfig.subscription}/subscriptionPlanList`;
    return this.resourceService.getData(subscriptionPlanListUrl);
  }
  // subscriptionPlanList() {
  //   const subscriptionPlanListUrl = this.sendURL + `${appConfig.subscription}/subscribePlanWithoutLogin?page=1&size=50`;
  //   return this.resourceService.getData(subscriptionPlanListUrl);
  // }

  subscriptionPlanById(id: any) {
    const subscriptionPlanByIdUrl = this.sendURL + `${appConfig.subscription}/subscribePlanWithoutLogin/${id}`;
    return this.resourceService.getData(subscriptionPlanByIdUrl);
  }

  changeSubscriptionPlan(subscriptionPlanId: any, userId: any) {
    const changeSubscriptionPlanUrl =
      this.sendURL +
      `${appConfig.subscription}/subscribePlan?subscriptionPlanId=${subscriptionPlanId}&userId=${userId}`;
    return this.resourceService.postData(changeSubscriptionPlanUrl);
  }

  subscribePlan(planId: any, userId: any) {
    const subscribePlanUrl =
      this.sendURL + `${appConfig.subscription}/subscribePlan?subscriptionPlanId=${planId}&userId=${userId}`;
    return this.resourceService.postData(subscribePlanUrl);
  }

  makePayment(data: any) {
    const addcardDetailsURL = this.sendURL + `${appConfig.giving}/payment`;
    return this.resourceService.postData(addcardDetailsURL, data);
  }

  makePaymentWithoutLogin(data: any) {
    const makePaymentWithoutLoginURL = this.sendURL + `${appConfig.giving}/paymentWithoutLogin`;
    return this.resourceService.postData(makePaymentWithoutLoginURL, data);
  }

  getAllCountryList() {
    const AllCountryListUrl = this.sendURL + `${appConfig.organization}/getAllCountryList`;
    return this.resourceService.getData(AllCountryListUrl);
  }

  createUser(data: any) {
    const createUserUrl = this.sendURL + `${appConfig.contact}/createUserWithUserLogin`;
    return this.resourceService.postData(createUserUrl, data);
  }

  getSecretKey() {
    const getSecretKeyURL = this.sendURL + `${appConfig.usersubScription}/secretKey?isGiving=true`;
    return this.resourceService.getData(getSecretKeyURL);
  }

  getSecretKeyWithoutLogin() {
    const getSecretKeyWithoutLoginURL = this.sendURL + `${appConfig.usersubScription}/secretKeyWithoutLogin`;
    return this.resourceService.getData(getSecretKeyWithoutLoginURL);
  }

  addCard(data: any) {
    const addCardUrl = this.sendURL + `${appConfig.usersubScription}/userCard`;
    return this.resourceService.postData(addCardUrl, data);
  }

  addCardWithoutLogin(data: any) {
    const addCardWithoutLoginUrl = this.sendURL + `${appConfig.usersubScription}`;
    return this.resourceService.postData(addCardWithoutLoginUrl, data);
  }

  cardDetails() {
    const cardDetailsUrl = this.sendURL + `${appConfig.usersubScription}/cardDetails`;
    return this.resourceService.getData(cardDetailsUrl);
  }

  cardDetailsForGiving(value: boolean) {
    const cardDetailsForGivingUrl =
      this.sendURL + `${appConfig.usersubScription}/mobileCardDetails?givingCard=${value}`;
    return this.resourceService.getData(cardDetailsForGivingUrl);
  }

  /* get Contact by Id */

  getContactById(id: any) {
    const getContactByIdURL = this.sendURL + `${appConfig.contact}/${id}`;
    return this.resourceService.getData(getContactByIdURL);
  }

  /* update contact */

  updateContact(id: any, data: any) {
    const updateContactURL = this.sendURL + `${appConfig.contact}/${id}`;
    return this.resourceService.putData(updateContactURL, data);
  }

  uploadBase64(stockData: any) {
    const uploadBaseUrl = this.sendURL + `${appConfig.upload}/base64images`;
    return this.resourceService.postData(uploadBaseUrl, stockData);
  }

  uploadBase64WithDomain(stockData: any, domain: string) {
    const uploadBaseUrl = this.sendURL + `${appConfig.upload}/base64images?domainName=${domain}`;
    return this.resourceService.postData(uploadBaseUrl, stockData);
  }

  uploadFile(formData: any, type: any, domain: string) {
    const uploadFileUrl = this.sendURL + `${appConfig.upload}/${type}?domainName=${domain}`;
    return this.resourceService.postData(uploadFileUrl, formData);
  }

  // media Play History

  postMediaPlayCount(data: any) {
    const postMediaPLayCountURL = this.sendURL + `${appConfig.mediaPlay}`;
    return this.resourceService.postData(postMediaPLayCountURL, data);
  }

  postMediaPlayCountWithouAuth(data: any) {
    const postMediaPLayCountURL = this.sendURL + `${appConfig.mediaPlay}/mediaPlayWithoutLogin`;
    return this.resourceService.postData(postMediaPLayCountURL, data);
  }

  getMassageTypeList(isLoggedIn: boolean) {
    if (isLoggedIn) {
      var getMassageTypeURL = this.sendURL + `${appConfig.contactUs}/getMessageTypeListWithLogin`;
    } else {
      var getMassageTypeURL = this.sendURL + `${appConfig.contactUs}/getMessageTypeList`;
    }
    return this.resourceService.getData(getMassageTypeURL);
  }

  postContactUsWithoutAuth(data: any) {
    const postContactUsWithoutURL = this.sendURL + `${appConfig.contactUs}/contactUsWithoutLoginForWeb`;
    return this.resourceService.postData(postContactUsWithoutURL, data);
  }

  postContactUsWithAuth(data: any) {
    const postContactUsWithURL = this.sendURL + `${appConfig.contactUs}`;
    return this.resourceService.postData(postContactUsWithURL, data);
  }

  getToken(token: any) {
    const getTokenURL = this.sendURL + `${appConfig.refreshToken}?tokenString=${token}`;
    return this.resourceService.getData(getTokenURL);
  }

  userPlanDetails(id: any) {
    const getUserPlanDetails = this.sendURL + `${appConfig.userAdmin}/userPlanDetails/${id}`;
    return this.resourceService.getData(getUserPlanDetails);
  }
  getMediaItems(id: number): Promise<any> {
    const prepareUrl = `${this.mediaRoot}admin-service/api/v1/mediaItem/${id}/metaTag`;
    return this.resourceService.getData(prepareUrl).toPromise();
  }

  updateAutoPlayStatus(status: any) {
    const AutoPlayStatusUrl =
      this.sendURL + `${appConfig.displayMediaItemInfo}/getAutoPlayStatus?isAutoPlayStatus=${status}`;
    return this.resourceService.putData(AutoPlayStatusUrl);
  }
  getMediaStatus(id: number): Promise<any> {
    const prepareUrl = `${this.sendURL}${appConfig.mediaStatus}/${id}`;
    return this.resourceService.getData(prepareUrl).toPromise();
  }

  getEbookSeriesList(searchKey: string) {
    const prepareUrl = `${this.sendURL}${appConfig.ebookList}/mediaItemAndSeriesList?seriesType=EBOOK_SERIES${
      searchKey ? `&searchkey=${searchKey}` : ''
    }`;
    return this.resourceService.getData(prepareUrl);
  }

  getEbookSeriesListWithout(searchKey: string) {
    const prepareUrl = `${this.sendURL}${appConfig.ebookList}/mediaItemsAndSeries?seriesType=EBOOK_SERIES${
      searchKey ? `&searchkey=${searchKey}` : ''
    }`;
    return this.resourceService.getData(prepareUrl);
  }

  validateUserPayment(videoId: any): Promise<any> {
    const prepareUrl = `${this.sendURL}${appConfig.displayMediaItemInfo}/videoPaymentStatus?mediaItemId=${videoId}`;
    return this.resourceService.getData(prepareUrl).toPromise();
  }
  getEbookMediaItem(id: number): Promise<any> {
    const prepareUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}`;
    return this.resourceService.getData(prepareUrl).toPromise();
  }
  getEbookMediaDataByIdWithout(id: any) {
    const getMediaDataByIdUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/nextVideoFromListWithoutLogin?currentPlayingMediaItemId=${id}&itemType=EBOOK`;
    return this.resourceService.getData(getMediaDataByIdUrl);
  }
  getEbookMediaDataByIdWith(id: any) {
    const getMediaDataByIdUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/nextVideoFromList?currentPlayingMediaItemId=${id}&itemType=EBOOK`;
    return this.resourceService.getData(getMediaDataByIdUrl);
  }
  getEbookRelatedMediaItemWithout(page: any, size: any, id: any) {
    const getRelatedMediaItemWithoutLoginUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/relatedMediaItem?currentPlayingMediaItemId=${id}&page=${page}&size=${size}&itemType=EBOOK`;
    return this.resourceService.getData(getRelatedMediaItemWithoutLoginUrl);
  }

  getEbookRelatedMediaItemWith(page: any, size: any, id: any) {
    const getRelatedMediaItemWithoutUrl =
      this.sendURL +
      `${appConfig.displayMediaItemInfo}/relatedMediaItemWithLogin?currentPlayingMediaItemId=${id}&page=${page}&size=${size}&itemType=EBOOK`;
    return this.resourceService.getData(getRelatedMediaItemWithoutUrl);
  }
  updateUserSubscription(planId: number) {
    const prepareUrl = `${this.sendURL}${appConfig.subscription}/websiteUserPlan?subscriptionPlanId=${planId}`;
    return this.resourceService.postData(prepareUrl, {});
  }
  getMobileAppBrandingWith() {
    const prepareUrl = `${this.sendURL}${appConfig.branding}`;
    return this.resourceService.getData(prepareUrl);
  }
  getMobileAppBrandingWithout() {
    const prepareUrl = `${this.sendURL}${appConfig.branding}/getBrandingInfo`;
    return this.resourceService.getData(prepareUrl);
  }
  getPageCustomizationByDomain(domain: string) {
    // const domain =
    //   this.sendURL && this.sendURL.replace('https://', '').replace('/', '');
    const prepareUrl = `${environment.dashboard}admin-service/api/v1/pageCustomization/PageCustomization?domainName=${domain}`;
    return this.resourceService.getData(prepareUrl);
  }
  getVast(domain: string) {
    // const domain =
    //   this.sendURL && this.sendURL.replace('https://', '').replace('/', '');
    const prepareUrl = domain;
    return this.resourceService.getData(prepareUrl);
  }
  getChannelList(date: any) {
    const prepareUrl = `${this.sendURL}${appConfig.channel}?date=${date}`;
    return this.resourceService.getData(prepareUrl);
  }
  getChannelListWithout(date: any) {
    const prepareUrl = `${this.sendURL}${appConfig.channel}/playlist?date=${date}`;
    return this.resourceService.getData(prepareUrl);
  }
  searchM3uData(id: number, searchKey: any, categoryName: any, channelType: any, isLoggedIn: boolean) {
    let getM3udataUrl: any;
    const encodedSearch = encodeURIComponent(searchKey);
    const encodedField = encodeURIComponent(categoryName);
    if (isLoggedIn) {
      if (categoryName && searchKey) {
        getM3udataUrl = `${appConfig.EbookMediaItem}/${id}/playlist?categoryName=${encodedField}&channelType=${channelType}&searchKey=${encodedSearch}`;
      } else if (categoryName) {
        getM3udataUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/playlist?categoryName=${encodedField}&channelType=${channelType}`;
      } else {
        getM3udataUrl = `${this.sendURL}${this.sendURL}${appConfig.EbookMediaItem}/${id}/playlist?channelType=${channelType}&searchKey=${encodedSearch}`;
      }
    } else {
      if (categoryName && searchKey) {
        getM3udataUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/m3uSearch?categoryName=${encodedField}&channelType=${channelType}&searchKey=${encodedSearch}`;
      } else if (categoryName) {
        getM3udataUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/m3uSearch?categoryName=${encodedField}&channelType=${channelType}`;
      } else {
        getM3udataUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/m3uSearch?channelType=${channelType}&searchKey=${encodedSearch}`;
      }
    }
    return this.resourceService.getData(getM3udataUrl);
  }
  getChannelItemById(mediaItemId: number, isLoggedIn: boolean, noOfItems: number) {
    if (isLoggedIn) {
      if (noOfItems) {
        var getChannelItemByIdUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}?noOfItems=${noOfItems}`;
      } else {
        var getChannelItemByIdUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}`;
      }
    } else {
      if (noOfItems) {
        var getChannelItemByIdUrl = `${this.sendURL}${appConfig.EbookMediaItem}/mediaItemId/${mediaItemId}?noOfItems=${noOfItems}`;
      } else {
        var getChannelItemByIdUrl = `${this.sendURL}${appConfig.EbookMediaItem}/mediaItemId/${mediaItemId}`;
      }
    }
    return this.resourceService.getData(getChannelItemByIdUrl);
  }
  getSchedulingChannelInfo(date: any, mediaItemId: number, isLoggedIn: boolean, isM3u8: boolean) {
    var schedulingChannelInfoUrl;
    if (isLoggedIn) {
      schedulingChannelInfoUrl = `${this.sendURL}${appConfig.schedulingChannelInfo}?date=${date}&mediaItemId=${mediaItemId}`;
    } else {
      schedulingChannelInfoUrl = `${this.sendURL}${appConfig.schedulingChannelInfo}/dayPlaylist?date=${date}&mediaItemId=${mediaItemId}`;
    }
    return this.resourceService.getData(schedulingChannelInfoUrl);
  }
  getBroadcastingStatusForChannel(streamId: any, isLoggedIn: boolean) {
    if (isLoggedIn) {
      var BroadcastingStatusForChannelUrl = `${this.sendURL}${appConfig.schedulingChannelInfo}/streamStatus?appName=ChannelApp&streamId=${streamId}`;
    } else {
      var BroadcastingStatusForChannelUrl = `${this.sendURL}${appConfig.schedulingChannelInfo}/broadcastStatus?appName=ChannelApp&streamId=${streamId}`;
    }
    return this.resourceService.getData(BroadcastingStatusForChannelUrl);
  }

  getNetworkData(date: any, id: number, noOfItems: number, isLoggedIn: boolean) {
    if (isLoggedIn) {
      var getNetworkInfoUrl = `${this.sendURL}${appConfig.mediaseries}/network/${id}?date=${date}&noOfItems=${noOfItems}`;
    } else {
      var getNetworkInfoUrl = `${this.sendURL}${appConfig.mediaseries}/networkId/${id}?date=${date}&noOfItems=${noOfItems}`;
    }
    return this.resourceService.getData(getNetworkInfoUrl);
  }

  getM3uDataByCatagory(
    id: number,
    catagoryName: string,
    channelType: any,
    isLoggedIn: boolean,
    page: number,
    size: number,
  ): Observable<any> {
    const encodedField = encodeURIComponent(catagoryName);
    if (isLoggedIn) {
      var getM3uDataByCatagoryUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/m3uPlaylist?categoryName=${encodedField}&channelType=${channelType}&page=${page}&size=${size}`;
    } else {
      var getM3uDataByCatagoryUrl = `${this.sendURL}${appConfig.EbookMediaItem}/${id}/m3u?categoryName=${encodedField}&channelType=${channelType}&page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getM3uDataByCatagoryUrl);
  }

  getM3uData(mediaItemId: any, m3uType: any, date: any, page: any, size: any, noOfItem: any, isLoggedIn: boolean) {
    if (isLoggedIn) {
      if (page && size) {
        if (m3uType === 'M3U_CHANNEL') {
          var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&date=${date}&page=${page}&size=${size}`;
        } else {
          var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&deviceType=WEB&noOfItems=${noOfItem}&page=${page}&size=${size}`;
        }
      } else {
        var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uData?channelType=${m3uType}&deviceType=WEB&noOfItems=${noOfItem}`;
      }
    } else {
      if (page && size) {
        if (m3uType === 'M3U_CHANNEL') {
          var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uChannel?channelType=${m3uType}&date=${date}&page=${page}&size=${size}`;
        } else {
          var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uChannel?channelType=${m3uType}&deviceType=WEB&noOfItems=${noOfItem}&page=${page}&size=${size}`;
        }
      } else {
        var m3uDataByTypeURL = `${this.sendURL}${appConfig.EbookMediaItem}/${mediaItemId}/m3uChannel?channelType=${m3uType}&deviceType=WEB&noOfItems=${noOfItem}`;
      }
    }
    return this.resourceService.getData(m3uDataByTypeURL);
    // mediaItem/59540/m3uChannel?channelType=M3U_MOVIES&organizationId=557&page=1&size=15
  }
  addToFavM3u(data: any) {
    const addToFavM3uURL = this.sendURL + `${appConfig.addInFavourites}`;
    return this.resourceService.postData(addToFavM3uURL, data);
  }
  removeFromFavM3u(channelName: any, channelType: any, id: any): any {
    const encodeName = encodeURIComponent(channelName);
    const removeFromFavM3uURL =
      this.sendURL + `${appConfig.favourites}?channelName=${encodeName}&channelType=${channelType}&ids=${id}`;
    return this.resourceService.deleteData(removeFromFavM3uURL);
  }
  getM3uDetail(channelName: any, channelType: any): any {
    const encodeName = encodeURIComponent(channelName);
    if (channelType === 'M3U_MOVIES') {
      var getM3uDetailURL =
        this.sendURL +
        `${appConfig.EbookMediaItem}/movieSeriesDetails?channelType=${channelType}&movieName=${encodeName}`;
    } else {
      var getM3uDetailURL =
        this.sendURL +
        `${appConfig.EbookMediaItem}/movieSeriesDetails?channelType=${channelType}&seriesName=${encodeName}`;
    }
    return this.resourceService.getData(getM3uDetailURL);
  }
  getDetailOfSeason(seasonNumber: any, seriesId: any, seriesName: any) {
    const getSeasonDetailURL =
      this.sendURL +
      `${appConfig.EbookMediaItem}/seriesEpisodeDetails?seasonNumber=${seasonNumber}&seriesId=${seriesId}&seriesName=${seriesName}`;
    return this.resourceService.getData(getSeasonDetailURL);
  }
  getM3uUrl(name: any, channelType: any, episodeNumber: any, seasonNumber: any, mediaItemId: any) {
    const encodeName = encodeURIComponent(name);
    if (seasonNumber) {
      var getM3uURlURL =
        this.sendURL +
        `${appConfig.EbookMediaItem}/playingUrl/${encodeName}?channelType=${channelType}&episodeNumber=${episodeNumber}&mediaItemId=${mediaItemId}&seasonNumber=${seasonNumber}`;
    } else {
      var getM3uURlURL =
        this.sendURL +
        `${appConfig.EbookMediaItem}/playingUrl/${encodeName}?channelType=${channelType}&mediaItemId=${mediaItemId}`;
    }
    return this.resourceService.getData(getM3uURlURL);
  }
  getCategoryList(id: any, channelType: any, isLoggedIn: boolean) {
    if (isLoggedIn) {
      var getCategoryListURL = this.sendURL + `${appConfig.EbookMediaItem}/${id}/category?channelType=${channelType}`;
    } else {
      var getCategoryListURL =
        this.sendURL + `${appConfig.EbookMediaItem}/${id}/categoryList?channelType=${channelType}`;
    }
    return this.resourceService.getData(getCategoryListURL);
  }

  private dimensionsSubject = new BehaviorSubject<{ width: number; height: number }>({ width: 0, height: 0 });
  dimensions$ = this.dimensionsSubject.asObservable();

  setDimensions(width: number, height: number) {
    this.dimensionsSubject.next({ width, height });
  }
}
